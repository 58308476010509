/**
 * @namespace action
 */

/**
 * Constants representing various actions related to job interviews, communications, and processes.
 *
 * - NEGATIVE_ANSWER_SENT: "Negative answer sent"
 * - LINCOLN_INTERVIEW: "Lincoln Interview"
 * - PHONE_CALL: "Phone call"
 * - JOB_OFFER_RECEIVED: "Job offer received"
 * - SHORT_LISTED: "Short-list sent"
 * - MESSAGE_EMAIL: "Message/E-mail"
 * - CANDIDATE_NOT_YET_CONTACTED: "Candidate not yet contacted"
 * - LONG_LIST: "Long-list"
 * - CANDIDATE_HIRED: "Candidate hired"
 * - FOLLOW_UP_ONBOARDING: "Follow up onboarding"
 * - REFERENCE_CHECK: "Reference check"
 * - CANDIDATE_REPORT_SEND: "Candidate's report sent"
 * - SEND_MCPH: "MCPH - Questionnaire sent"
 * - CLIENT_INTERVIEW: "Client interview"
 * - MCPH_DEBRIEFING: "MCPH Debriefing"
 * - SEND_TALE_ME_MORE: "Tale me more sent"
 * - TALE_ME_MORE_DEBRIEFING: "Tale me more debriefing"
 * - NO_ACTION: "No action yet"
 * - PHONE_SKYPE_INTERVIEW: "Phone/skype interview"
 * - SHORT_LISTED_RESULT: "Short-list"
 * - LINKEDIN_INVITATION: "Linkedin invitation"
 * @readonly
 * @enum {string}
 *
 * @memberof action
 */
export const ACTION = {
  NEGATIVE_ANSWER_SENT: "Negative answer sent",
  LINCOLN_INTERVIEW: "Lincoln Interview",
  PHONE_CALL: "Phone call",
  JOB_OFFER_RECEIVED: "Job offer received",
  SHORT_LISTED: "Short-list sent",
  MESSAGE_EMAIL: "Message/E-mail",
  CANDIDATE_NOT_YET_CONTACTED: "Candidate not yet contacted",
  LONG_LIST: "Long-list",
  CANDIDATE_HIRED: "Candidate hired",
  FOLLOW_UP_ONBOARDING: "Follow up onboarding",
  REFERENCE_CHECK: "Reference check",
  CANDIDATE_REPORT_SEND: "Candidate's report sent",
  SEND_MCPH: "MCPH - Questionnaire sent",
  CLIENT_INTERVIEW: "Client interview",
  MCPH_DEBRIEFING: "MCPH Debriefing",
  SEND_TALE_ME_MORE: "Tale me more sent",
  TALE_ME_MORE_DEBRIEFING: "Tale me more debriefing",
  NO_ACTION: "No action yet",
  PHONE_SKYPE_INTERVIEW: "Phone/skype interview",
  SHORT_LISTED_RESULT: "Short-list",
  LINKEDIN_INVITATION: "Linkedin invitation",
};

/**
 * Enum for onboarding action types.
 *
 * @enum {string}
 */
export const ON_BOARDING_ACTION_TYPES = {
  POINT_ONBOARDING: "Point onboarding",
  BUSINESS_DEVELOPMENT: "Business development",
  WEEKLY_UPDATE: "Weekly meeting",
  PIPE: "Pipe",
  DOCUMENT: "Document",
};
